import React, { useRef } from "react";
import Modal from "components/elements/modal/Modal";
import { useHttp } from "hooks";
import { useToastContext, ADD } from "context/ToastContext";
import PDFLogo from "assets/images/logo/badili-logo.png";
import { Form, Input, DatePicker, Checkbox, Typography, Row, Col } from "antd";
import html2pdf from "html2pdf.js";
const { TextArea } = Input;
const { Title, Text } = Typography;

export default function ProcessWarrantyModal(props) {
  const { toastDispatch } = useToastContext();
  const pdfRef = useRef(null);
  const [form] = Form.useForm();
  const { error, loading, sendHttpRequest: processWarranty } = useHttp();
  const handleConfirmSubmit = () => {
     handleDownloadPDF();
    processWarranty(`process_warranty/${props?.id}`, "PUT", null, ({ msg }) => {
      props.close();
      props.refresh();
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    });
  };

  const handleDownloadPDF = () => {
    const randomNumber = Math.floor(Math.random() * 1000); // Adjust as needed
    const filename = `badili-service-jobcard-${randomNumber}.pdf`;
    // Get the HTML content you want to convert to PDF
    const htmlContent = document.getElementById("BadiliServiceJobCard");
    // Configuration for html2pdf
    const options = {
      margin: 10,
      filename: filename,
      // image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      pagebreak: { mode: "avoid-all" },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    // Use html2pdf to convert HTML to PDF and download
    html2pdf().from(htmlContent).set(options).save();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Modal
      close={props.close}
      onAccept={() => form.submit()}
      onReject={props.close}
      title={
        <>
          <b>Process Warranty</b> ( Would you like to process this device&nbsp;
          <span className="text-danger">{props?.name ?? "unknown"}</span> for
          warranty? )
        </>
      }
      form="processWarranty"
      loading={loading}
      size="lg"
      AcceptText={"Submit & Download"}
    >
      <Form
        layout="vertical"
        id="processWarranty"
        initialValues={{ needDeliveryNote: "no" }}
        form={form}
        onFinish={handleConfirmSubmit}
        onFinishFailed={onFinishFailed}
      >
        <hr />
        <div
          style={{
            fontFamily: "Arial, sans-serif",
          }}
          id="BadiliServiceJobCard"
          ref={pdfRef}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontSize: "10px", lineHeight: "1.2" }}>
                <Title level={5}>
                  <b>SERVICE JOB CARD</b>
                </Title>
                <div style={{ marginTop: -15 }}>
                  <p style={{ margin: 0, fontSize: "x-small" }}>
                    Emerging Ecomm Ventures Inc LTD
                  </p>
                  <p style={{ margin: 0, fontSize: "x-small" }}>
                    P.O Box 8744-00100,
                    <br />
                    NAIROBI.
                  </p>
                  <p style={{ margin: 0, fontSize: "x-small" }}>
                    Chiromo Courts, Waiyaki Way
                  </p>
                  <p
                    style={{
                      margin: 0,
                      fontWeight: "bold",
                      fontSize: "x-small",
                    }}
                  >
                    0725 62 62 62
                  </p>
                </div>
              </div>

              <div>
                <img alt="ImageName" width="118" src={PDFLogo}></img>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <div>
                <label>
                  JOB NO:
                  <Input style={{ width: 200 }} allowClear />
                </label>
              </div>
              <div>
                <label>
                  Date In: <DatePicker style={{ width: 200 }} />
                </label>
              </div>
            </div>
            {/* Header */}
            <Row gutter={16}>
              <Col span={16}>
                <Title
                  level={5}
                  style={{ backgroundColor: "#6AC4C6", padding: 2 }}
                >
                  CUSTOMER
                </Title>
                <div>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <p style={{ fontSize: "x-small" }}>
                        CUSTOMER: <Input />
                      </p>
                    </Col>
                    <Col span={12}>
                      <p style={{ fontSize: "x-small" }}>
                        CONTACT: <Input />
                      </p>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <p style={{ fontSize: "x-small" }}>
                        COLLECTION POINT:
                        <Input />
                      </p>
                    </Col>
                    <Col span={12}>
                      <p style={{ fontSize: "x-small" }}>
                        COLLECTION CONTACT:
                        <Input />
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={8}>
                <Title
                  level={5}
                  style={{ backgroundColor: "#6AC4C6", padding: 2 }}
                >
                  Password/Pattern/PIN
                </Title>
                <div>
                  <TextArea rows={4} />
                </div>
              </Col>
            </Row>
            {/* Handset Details */}
            <Row>
              <Col span={24}>
                <Title
                  level={5}
                  style={{
                    backgroundColor: "#6AC4C6",
                    padding: 2,
                    textAlign: "center",
                  }}
                >
                  HANDSET DETAILS
                </Title>
              </Col>
            </Row>
            <Row>
              <Row gutter={16}>
                <Col span={8} style={{ fontSize: "x-small" }}>
                  Handset Make: <Input />
                </Col>
                <Col span={8} style={{ fontSize: "x-small" }}>
                  Model: <Input />
                </Col>
                <Col span={8} style={{ fontSize: "x-small" }}>
                  Variant: <Input />
                </Col>
              </Row>
              <Row gutter={32}>
                {/* Left Side */}
                <Col span={14}>
                  <Row gutter={16}>
                    <Col span={24} style={{ fontSize: "x-small" }}>
                      IMEI #:
                      <Input.Group compact>
                        {Array(15)
                          .fill(null)
                          .map((_, idx) => (
                            <Input
                              key={`imei-${idx}`}
                              style={{
                                width: "calc(100% / 15)",
                                fontSize: "x-small",
                                textAlign: "center",
                              }}
                              maxLength={1}
                            />
                          ))}
                      </Input.Group>
                    </Col>
                  </Row>
                  <Row
                    gutter={16}
                    style={{
                      marginTop: 14,
                    }}
                  >
                    <Col span={24} style={{ fontSize: "x-small" }}>
                      S/N #:
                      <Input.Group compact>
                        {Array(15)
                          .fill(null)
                          .map((_, idx) => (
                            <Input
                              key={`sn-${idx}`}
                              style={{
                                width: "calc(100% / 15)",
                                fontSize: "x-small",
                                textAlign: "center",
                              }}
                              maxLength={1}
                            />
                          ))}
                      </Input.Group>
                    </Col>
                  </Row>
                </Col>

                {/* Right Side */}
                <Col span={10}>
                  <Row gutter={16}>
                    <Col
                      span={24}
                      style={{
                        marginTop: 14,
                        marginBottom: 14,
                        fontSize: "x-small",
                      }}
                    >
                      WARRANTY:
                      <Checkbox.Group>
                        <Checkbox value="Yes" style={{ fontSize: "x-small" }}>
                          YES
                        </Checkbox>
                        <Checkbox value="No" style={{ fontSize: "x-small" }}>
                          NO
                        </Checkbox>
                      </Checkbox.Group>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24} style={{ fontSize: "x-small" }}>
                      Date Of Purchase: <DatePicker style={{ width: "100%" }} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Col span={24}>
                <Title level={5}>ACCESSORIES:</Title>
                <Checkbox.Group style={{ fontSize: "x-small", marginTop: -15 }}>
                  <Checkbox value="SIMCARD" style={{ fontSize: "x-small" }}>
                    SIMCARD
                  </Checkbox>
                  <Checkbox value="CHARGER" style={{ fontSize: "x-small" }}>
                    CHARGER
                  </Checkbox>
                  <Checkbox
                    value="PHONE CASING"
                    style={{ fontSize: "x-small" }}
                  >
                    PHONE CASING
                  </Checkbox>
                  <Checkbox value="MEMORY CARD" style={{ fontSize: "x-small" }}>
                    MEMORY CARD
                  </Checkbox>
                </Checkbox.Group>
              </Col>
            </Row>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* Descriptions */}

              <p style={{ fontSize: "x-small" }}>
                FAULT DESCRIPTION:
                <TextArea rows={1} />
              </p>
              <p style={{ fontSize: "x-small" }}>
                REPAIR DESCRIPTION:
                <TextArea rows={1} />
              </p>
              <p style={{ fontSize: "x-small" }}>
                OTHER REMARKS:
                <TextArea rows={1} />
              </p>
            </div>
            {/* Payment Section */}
            <Row gutter={16} style={{ marginTop: -10 }}>
              <Col span={8}>
                <p style={{ fontSize: "x-small" }}>
                  Amount Charged: <Input />
                </p>
              </Col>
              <Col span={8}>
                <p style={{ fontSize: "x-small" }}>
                  Deposit: <Input />
                </p>
              </Col>
              <Col span={8}>
                <p style={{ fontSize: "x-small" }}>
                  Bal.: <Input />
                </p>
              </Col>
            </Row>
            {/* Mode of Payment */}
            <Row style={{ marginTop: -15 }}>
              <Col span={24}>
                <p style={{ fontSize: "x-small" }}>
                  Mode of Payment:
                  <Checkbox.Group>
                    <Checkbox value="Cash" style={{ fontSize: "x-small" }}>
                      Cash
                    </Checkbox>
                    <Checkbox value="Mpesa" style={{ fontSize: "x-small" }}>
                      Mpesa
                    </Checkbox>
                  </Checkbox.Group>
                </p>
              </Col>
            </Row>
            <div style={{ marginTop: -15 }}>
              <Title level={5}>Booking:</Title>
              <Row gutter={16} style={{ marginTop: -10 }}>
                <Col span={8}>
                  <p style={{ fontSize: "x-small" }}>
                    Customer Signature:
                    <Input style={{ width: "100%" }} />
                  </p>
                </Col>
                <Col span={8}>
                  <p style={{ fontSize: "x-small" }}>
                    Staff Signature:
                    <Input style={{ width: "100%" }} />
                  </p>
                </Col>
                <Col span={8}>
                  <p style={{ fontSize: "x-small" }}>
                    Date:
                    <DatePicker style={{ width: "100%" }} />
                  </p>
                </Col>
              </Row>
            </div>
            <div style={{ marginTop: -15 }}>
              <Title level={5}>Collection:</Title>
              <Row gutter={16} style={{ marginTop: -10 }}>
                <Col span={8}>
                  <p style={{ fontSize: "x-small" }}>
                    Customer Signature:
                    <Input style={{ width: "100%" }} />
                  </p>
                </Col>
                <Col span={8}>
                  <p style={{ fontSize: "x-small" }}>
                    Staff Signature:
                    <Input style={{ width: "100%" }} />
                  </p>
                </Col>
                <Col span={8}>
                  <p style={{ fontSize: "x-small" }}>
                    Date:
                    <DatePicker style={{ width: "100%" }} />
                  </p>
                </Col>
              </Row>
            </div>
            <div style={{ marginTop: -15 }}>
              <Text italic strong style={{ color: "#6AC4C6", fontSize: "8px" }}>
                Terms and conditions
              </Text>
              <ol style={{ fontSize: "8px" }}>
                <li>
                  The above product is covered under 12 months warranty from the
                  date of purchase.
                </li>
                <li>
                  Third party software, settings and contents are not covered by
                  warranty.
                </li>
                <li>
                  Any damage caused by mishandling, like dropping, scratching,
                  liquid damage, and etc, is not covered by warranty.
                </li>
                <li>
                  Warranty will be void if the product has been opened, modified
                  or repaired by anyone other than Badili service centre.
                </li>
                <li>
                  Please show any proof of purchase like receipt for warranty
                  validation.
                </li>
                <li>
                  Out warranty product will be charged for booking and repair
                  fee.
                </li>
                <li>
                  Out warranty repair will be charged at Badili service centre's
                  normal rates.
                </li>
                <li>
                  Data will be lost during software downloading or formatting.
                </li>
                <li>
                  Any out of warranty job which is not collected within 2 months
                  will be scrapped.
                </li>
                <li>
                  Transport will be charged for in-home visits for
                  out-of-warranty repairs.
                </li>
              </ol>

              <Text
                type="secondary"
                style={{ float: "right", color: "#6AC4C6", fontSize: "8px" }}
              >
                www.badili.africa
              </Text>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
}
