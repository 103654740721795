import React, { useEffect, useState } from "react";
import { ListView } from "layouts";
import { PageHeaderComponent, Loader, Table } from "components/elements";
import { format } from "date-fns";
import { MenuOutlined, ShopOutlined } from "@ant-design/icons";
import { useHttp } from "hooks";
import { Button, Dropdown, Menu } from "antd";
import ModalService from "components/elements/modal/ModalService";
import UserService from "services/UserService";
import ProcessWarrantyModal from "./ProcessWarrantyModal";

export const thousandCommaSeparator = (number) => {
  let nf = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency:
      UserService.getCurrentUserLoginData().country === "ug"
        ? "UGX"
        : UserService.getCurrentUserLoginData().country === "ke"
        ? "KES"
        : UserService.getCurrentUserLoginData().country === "tz"
        ? "TZS"
        : UserService.getCurrentUserLoginData().country === "in"
        ? "INR" // India
        : UserService.getCurrentUserLoginData().country === "ng"
        ? "NGN" // Nigeria
        : " ", 
  });

  return nf.format(number);
};
const ListProducts = () => {
  const [pageData, setPageData] = useState({
    rowData: [],
    isLoading: false,
    totalPages: 0,
    totalProducts: 0,
  });
  const {
    error: errorInventory,
    loading: loadingInventory,
    sendHttpRequest: getWarrantytory,
  } = useHttp();

  const openProcessWarrantyModal = (id, name) => {
    ModalService.open(ProcessWarrantyModal, {
      id,
      name,
      refresh: getWarranty,
    });
  };

  useEffect(() => {
    setPageData((prevState) => ({
      ...prevState,
      rowData: [],
      isLoading: true,
    }));
    getWarranty();
  }, []);

  const handlInventoryData = ({ data: { warranty } }) => {
    let tempData = [];
    warranty?.map((val) => {
      return tempData.push({
        name: val?.model?.[0]?.modelName || "-", // Safe navigation for `model`
        imei: val?.imei || "-",
        _id: val?._id || "-",
        purchase_Date: val?.purchaseDate || "-",
        warranty_Date: val?.warrantyDate || "-",
        active: val?.active ?? false, // Default to false if undefined
        customername: `${val?.fname || ""} ${val?.lname || ""}`.trim(),
        customercontact: val?.phone || "-",
        customeremail: val?.email || "-",
        purchase_location:
          val?.location_dealer || val?.location_warehouse || "-",
        action: { _id: val?._id, name: val?.model?.[0]?.modelName },
      });
    });
    setPageData({
      isLoading: false,
      rowData: tempData,
      totalProducts: warranty.length,
    });
  };

  const getWarranty =  () => {
    getWarrantytory(`get_all_warranty`, "GET", null, handlInventoryData);
  };

  const columns = [
    {
      label: "ACTION",
      name: "action",
      options: {
        filter: false,
        download: false,
        print: false,
        sort: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className="list-icons">
                <Dropdown
                  overlay={
                    <Menu>
                     
                      <Menu.Item
                        key="7"
                        onClick={() =>
                          openProcessWarrantyModal(value?._id, value?.name)
                        }
                      >
                        Process Warranty
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <Button
                    className="list-icons-item dropdown-toggle caret-0 border-0 bg-transparent outline-0"
                    icon={<MenuOutlined />}
                  />
                </Dropdown>
              </div>
            </>
          );
        },
      },
    },
    {
      label: "Product Name",
      name: "name",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value}</div>
            </>
          );
        },
      },
    },
    {
      label: "PRODUCT IMEI",
      name: "imei",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "-";
        },
      },
    },
    {
      label: "PURCHASE LOCATION",
      name: "purchase_location",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value}</>;
        },
      },
    },
    {
      label: "PURCHASE DATE",
      name: "purchase_Date",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? format(new Date(value), "PP") : "-";
        },
      },
    },
    {
      label: "WARANTY DATE",
      name: "warranty_Date",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? format(new Date(value), "PP") : "-";
        },
      },
    },
    {
      label: "STATUS",
      name: "active",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>
                {value ? (
                  <>
                    <span
                    
                      style={{
                        backgroundColor: "green",
                        display: "inline-block",
                      }}
                    />
                    <span style={{ color: "green" }}>Active</span>
                  </>
                ) : (
                  <>
                    <span
                     
                      style={{
                        backgroundColor: "red",
                        display: "inline-block",
                      }}
                    />
                    <span style={{ color: "red" }}>Expired</span>
                  </>
                )}
              </div>
            </>
          );
        },
      },
    },
    {
      label: "CUSTOMER NAME",
      name: "customername",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value}</div>
            </>
          );
        },
      },
    },
    {
      label: "CUSTOMER CONTACT",
      name: "customercontact",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value}</div>
            </>
          );
        },
      },
    },
    {
      label: "CUSTOMER EMAIL",
      name: "customeremail",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
      },
    },
  ];

  return (
    <>
      {loadingInventory && <Loader />}

      {!loadingInventory && (
        <ListView
          pageHeader={
            <PageHeaderComponent
              title="Warranty Dashboard"
              subtitle=""
              icon="icofont-home"
              extras={
                <div className="card-body">
                  <div className="d-flex flex-wrap">
                    <div className="mb-3 mb-md-0">
                      <div className="ml-3 text-center">
                        <h3 className="font-weight-semibold mb-0">
                          {pageData?.totalProducts}
                        </h3>
                        <span>Available Devices</span>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              }
            />
          }
          table={
            <Table
              title={"Warranty "}
              data={pageData.rowData}
              columns={columns}
            />
          }
          data={pageData.rowData}
        />
      )}
    </>
  );
};

export default ListProducts;
