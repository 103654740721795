export const salesSupervisorLinks = [
  {
    name: "Sell In",
    icon: "icofont-home",
    route: "/sales-supervisor/dashboard",
  },
  {
    name: "Agents",
    icon: "icofont-users",
    route: "/sales-supervisor/agents",
  },
  {
    name: "Get All Sell Out",
    icon: "icofont-chart-histogram",
    route: "/sales-supervisor/sell-out",
  },
  {
    name: "Accessories",
    icon: "icofont-basket",
    route: `/sales-supervisor/accessories`,
  },
];
