import { AccessoriesRoutes } from "./modules/Accessories/AccessoriesRoutes";
import AgentRoutes from "./modules/agents/AgentRoutes";
import SellInRoutes from "./modules/sellIn/SellInRoutes";
import SellOutRoute from "./modules/sellOut/SellOutRoutes";

export const salesSupervisorRoutes = [
  ...AgentRoutes,
  ...SellInRoutes,
  ...SellOutRoute,
  ...AccessoriesRoutes
];
