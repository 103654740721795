import React from "react";
import { Dashboard } from "layouts";
import { warrantyRoutes } from "app/warranty/warrantyRoutes";
import { WarrantyLinks } from "app/warranty/modules/general/landing/WarrantyLinks";

const WarrantyDashboard = ({ country }) => {
  return (
    <Dashboard
      routes={warrantyRoutes}
      name="Warranty Dashboard"
      sidebarLinks={WarrantyLinks}
      country={country}
    />
  );
};

export default WarrantyDashboard;
