import React, { useEffect, useState } from "react";
import { ListView } from "layouts";
import { PageHeaderComponent, Loader, Table } from "components/elements";
import { format } from "date-fns";
import { MenuOutlined, ShopOutlined } from "@ant-design/icons";
import { useHttp } from "hooks";
import { Button, Dropdown, Menu } from "antd";
import MakeSaleModal from "./MakeSaleModal";
import ModalService from "components/elements/modal/ModalService";
import UserService from "services/UserService";
import ReturnModal from "../sellOut/ReturnModal";
export const thousandCommaSeparator = (number) => {
  let nf = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency:
      UserService.getCurrentUserLoginData().country === "ug"
        ? "UGX"
        : UserService.getCurrentUserLoginData().country === "ke"
        ? "KES"
        : UserService.getCurrentUserLoginData().country === "tz"
        ? "TZS"
        : UserService.getCurrentUserLoginData().country === "in"
        ? "INR" // India
        : UserService.getCurrentUserLoginData().country === "ng"
        ? "NGN" // Nigeria
        : " ", 
  });

  return nf.format(number);
};
const ListProducts = () => {
  const [pageData, setPageData] = useState({
    rowData: [],
    isLoading: false,
    totalPages: 0,
    totalProducts: 0,
  });
  const {
    error: errorInventory,
    loading: loadingInventory,
    sendHttpRequest: getInventory,
  } = useHttp();

  const openReturnDeviceModal = (id, InventoryId, title) => {
    ModalService.open(ReturnModal, {
      id,
      InventoryId,
      title,
      refresh: getInven,
    });
  };
  const openMakeASaleModal = (id, InventoryId, title) => {
    ModalService.open(MakeSaleModal, {
      id,
      InventoryId,
      title,
      refresh: getInven,
    });
  };
  useEffect(() => {
    setPageData((prevState) => ({
      ...prevState,
      rowData: [],
      isLoading: true,
    }));
    getInven();
  }, []);

  const handlInventoryData = ({ data: { requests } }) => {
    let tempData = [];
    requests?.map((el) => {
      return tempData.push({
        _id: el?._id,
        consiDate: format(new Date(el?.consignmentDate), "dd MMM yyyy"),
        model: el?.model?.modelName,
        model_id: el?.model?._id,
        variant:
          el?.variant[0]?.ram !== undefined
            ? `${el?.variant[0]?.ram}GB/${el?.variant[0]?.storage}${el?.variant[0]?.storageType}`
            : "",
        imei: el?.imei && el?.imei !== undefined ? el?.imei : "-",
        serial_number:
          el?.serialNumber && el?.serialNumber !== undefined
            ? el?.serialNumber
            : "-",
        warehouse: el?.warehouse?.name !== undefined && el?.warehouse?.name,
        assignedColor: el?.assignedColor[0]?.name ?? null,
        action: [el?._id, el?.sold, el?.model?.modelName, el?.model?._id, el],
        status: el?.current_status[0]?.name?.toUpperCase(),
      });
    });
    setPageData({
      isLoading: false,
      rowData: tempData,
      totalProducts: requests.length,
    });
  };

  const getInven = async () => {
    getInventory(`get_all_sellin`, "GET", null, handlInventoryData);
  };

  const columns = [
    {
      label: "ACTION",
      name: "action",
      options: {
        filter: false,
        download: false,
        print: false,
        sort: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className="list-icons">
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="6"
                        onClick={() => openMakeASaleModal(value[0], value[4])}
                      >
                        Make a sale
                      </Menu.Item>
                      <Menu.Item
                        key="7"
                        onClick={() =>
                          openReturnDeviceModal(value[0], value[4])
                        }
                      >
                        Return Device
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <Button
                    className="list-icons-item dropdown-toggle caret-0 border-0 bg-transparent outline-0"
                    icon={<MenuOutlined />}
                  />
                </Dropdown>
              </div>
            </>
          );
        },
      },
    },
    {
      label: "Consignment Date",
      name: "consiDate",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <h6 className="mb-0">
                {format(new Date(value), "dd")} {format(new Date(value), "MMM")}
              </h6>
              <div className="font-size-sm text-muted line-height-1">
                {format(new Date(value), "yyyy")}
              </div>
            </>
          );
        },
      },
    },
    {
      name: "model_id",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      label: "Product Name",
      name: "model",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
      },
    },
    {
      label: "VARIANT",
      name: "variant",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className="font-weight-semibold">{value}</div>
            </>
          );
        },
      },
    },

    {
      label: "IMEI",
      name: "imei",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
      },
    },

    {
      label: "Color",
      name: "assignedColor",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value ? value : <p>Color not set</p>}</>;
        },
      },
    },
    {
      label: "Warehouse",
      name: "warehouse",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
      },
    },
  ];

  return (
    <>
      {loadingInventory && <Loader />}

      {!loadingInventory && (
        <ListView
          pageHeader={
            <PageHeaderComponent
              title="Sales Supervisor Dashboard"
              subtitle=""
              icon="icofont-home"
              extras={
                <div className="card-body">
                  <div className="d-flex flex-wrap">
                    <div className="mb-3 mb-md-0">
                      <div className="ml-3 text-center">
                        <h3 className="font-weight-semibold mb-0">
                          {pageData?.totalProducts}
                        </h3>
                        <span>Available Devices</span>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              }
            />
          }
          table={
            <Table
              title={"Sell In Allocation"}
              data={pageData.rowData}
              columns={columns}
            />
          }
          data={pageData.rowData}
        />
      )}
    </>
  );
};

export default ListProducts;
