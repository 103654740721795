import React, { useState, useEffect } from "react";
import { useHttp } from "hooks";
import { formatDate } from "utils";
import { Loader, PageHeaderComponent, Table } from "components/elements";
import { ListView } from "layouts";

export default function Accessories() {
  const [requests, setRequests] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const {
    error,
    loading,
    sendHttpRequest: getShippingCompanyRequests,
  } = useHttp();

  const handleData = ({ success, data: { find } }) => {
    let tempData = [];
    if (success === true) {
      find?.map((val) => {
        return tempData.push({
          name: val?.sku?.name || "-", // Safe navigation for `model`
          quantity: val?.quantity || 0,
          soldQuantity: val?.soldQuantity || 0,
          dispatchedQuantity: val?.dispatchedQuantity || 0,
          createdAt: val?.createdAt || "-",
          country: val?.country ?val?.country?.toUpperCase() : "-",
          _id: val?._id,
          serialName:val?.name,
          stockId: val?.stockId || "-", // Default to false if undefined
        });
      });
    }
    if (success === false) {
      setRequests([]);
    }
    setRequests(tempData);
  };
  function getShippingCompanyRequestsData() {
    return getShippingCompanyRequests(
      `get_accessories`,
      "GET",
      null,
      handleData
    );
  }

  useEffect(() => {
    getShippingCompanyRequestsData();
  }, []);

  useEffect(() => {
    setRowCount(requests?.length);
  }, [requests]);

  useEffect(() => {
    setRowCount(filteredData?.length);
  }, [filteredData]);

  const columns = [
    {
      label: "Name",
      name: "name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value}</div>
            </>
          );
        },
      },
    },
    {
      label: "Serial",
      name: "serialName",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value}</div>
            </>
          );
        },
      },
    },
    {
      label: " Quantity",
      name: "quantity",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
      },
    },
    {
      label: "Sold Quantity",
      name: "soldQuantity",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
      },
    },
    {
      label: "Dispatched Quantity",
      name: "dispatchedQuantity",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
      },
    },
    {
      label: "Country",
      name: "country",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
      },
    },
    {
      label: "Date",
      name: "createdAt",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? formatDate(value) : "-";
        },
      },
    },
  ];
  const routes = [
    {
      path: "",
      breadcrumbName: "Home",
    },
    {
      path: "",
      icon: "icofont-vehicle-delivery-van",
      breadcrumbName: "Accessories",
    },
  ];
  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <ListView
          data={requests}
          pageHeader={
            <PageHeaderComponent
              routes={routes}
              title="Accessories"
              subtitle=""
              icon="icofont-vehicle-delivery-van"
            />
          }
          table={
            <Table
              title={"Accessories"}
              data={requests}
              columns={columns}
              option={{
                customToolbar: () => (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        marginBottom: "16px",
                        fontSize: "18px",
                        marginLeft: "-58px",
                      }}
                    >
                      Total Row Count: <b>{rowCount}</b>
                    </div>
                  </div>
                ),
                onTableChange: (action, tableState) => {
                  if (action === "filterChange" || action === "search") {
                    setFilteredData(
                      tableState.displayData.map((row) => row.data)
                    );
                  }
                },
              }}
            />
          }
        />
      )}
    </>
  );
}
