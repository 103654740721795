import React from "react";
import Accessories from "./Accessories";

export const AccessoriesRoutes = [
  {
    path: `/accessories`,
    exact: true,
    element: <Accessories />,
  },
];
